<!-- 出库明细 -->

<template>
  <div class="leaveDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="orderValue" placeholder="搜索出库单号" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="codeValue" placeholder="搜索商品条形码" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="nameValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="出库单号"></el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productNum" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productPrice" label="供货价(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="商品数量"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="totalAmount" label="总金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="出库时间"
            width="180"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      codeValue: "", // 搜索商品条形码
      nameValue: "", // 搜索商品名称
      orderValue: "", // 搜索出库单号
      orderOptions: [{
        value: '0',
        label: '出库'
      }, {
        value: '1',
        label: '入库'
      },],
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  created() {
    this.orderValue = this.$route.query.orderNum || '';
    this.getInventoryOutProduct();
  },
  methods: {
    // 获取列表
    getInventoryOutProduct() {
      this.loading = true;
      this.$axios
        .get(this.$api.getInventoryOutProduct, {
          params: {
            orderNum: this.orderValue || null, // 搜索出库单号
            // productNum: this.codeValue || null, // 搜索商品编号
            barCode: this.codeValue || null, // 
            productName: this.nameValue || null, // 搜索商品名称
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getInventoryOutProduct();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getInventoryOutProduct();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getInventoryOutProduct();
    },
  },
};
</script>

<style lang="scss" scoped>
.leaveDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }
  }
}
</style>
